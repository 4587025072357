import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PortalUser } from '../authSlice';
import { BoddPortalAuthContext } from '../BoddAuthProvider/BoddPortalAuthContext';
import AuthUtils from '../auth-utils';

const fromContext = (userDetails: any) => {
    const portalUser = {
        userId: userDetails.userId!,
        merchantId: userDetails.merchantId!,
        name: userDetails.name!,
        email: userDetails.email,
        firstName: userDetails.name!,
        roles: userDetails.roles ?? [],
        profileImgUrl: '',
        enrolledAuthenticators: userDetails.enrolledAuthenticators,
    };
    return portalUser;
};

interface BoddPortalAuthProviderProps {
    children: React.ReactNode;
}

const BoddPortalAuthProvider: FC<BoddPortalAuthProviderProps> = ({ children }) => {
    const login = useCallback((opts?: { appState: { returnTo: string } }) => {
        const returnTo = opts?.appState?.returnTo || '/';
        window.location.href = `/login?userDeviceId=${AuthUtils.getUserDeviceId()}&returnTo=${returnTo}`;
        return Promise.resolve();
    }, []);

    const logout = useCallback(() => {
        window.location.href = '/logout';
    }, []);

    const navigate = useNavigate();
    const [user, setUser] = useState<PortalUser | null>(null);
    const [authLoading, setAuthLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const isInitialised = useRef(false);
    const initialising = useRef(false);
    const initialise = async () => {
        if (!isInitialised.current && !initialising.current) {
            initialising.current = true;
            const detailsReponse = await fetch('/user-details');
            const payload = await detailsReponse.text();
            if (payload) {
                const portalUser = JSON.parse(payload);
                setUser(fromContext(portalUser));
                setIsAuthenticated(true);
            } else {
                setUser(null);
                setIsAuthenticated(false);
            }
            isInitialised.current = true;
            setAuthLoading(false);
        }
    };

    useEffect(() => {
        initialise();
    }, []);

    const signInSilent = async () => {
        if (!user && !authLoading && isInitialised.current) {
            if (window.location.pathname.length > 1) {
                navigate(`/?returnTo=${window.location.pathname}`); // hacky
            }
        }
    };

    const error = useMemo(() => undefined, []);

    const contextValue = useMemo(() => ({
        isAuthenticated,
        isLoading: authLoading || !isInitialised,
        login,
        logout,
        error,
        user,
        loginSilent: () => signInSilent(),
    }), [isAuthenticated, authLoading]);

    return <BoddPortalAuthContext.Provider value={contextValue}>{children}</BoddPortalAuthContext.Provider>;
};

export default BoddPortalAuthProvider;
