import React, { FC, useState } from 'react';
import { Box, Button, LinearProgress, List, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginButton from '../../auth/LoginButton/LoginButton';
import { useAppSelector } from '../../../../app/hooks';
import {
    selectAuthenticatedUser,
    selectCurrentMerchantId, selectHasEnrolledAuthenticators,
    selectIsAuthLoading,
    selectIsBoddUser,
    selectIsBoddUserImpersonatingMerchant,
    selectIsEnrolledInAuthenticatorApp,
} from '../../auth/authSlice';
import { LogoutIcon, UserProfileIcon } from '../IconSelectorComponent/IconSelectorComponent';
import RegionSelectorMenuItem from '../RegionSelectorMenuItem/RegionSelectorMenuItem';
import ImpersonateMerchantMenuItem from '../ImpersonateMerchantMenuItem/ImpersonateMerchantMenuItem';
import { MessageType } from '../../helpers/useBroadcastChannel';
import useMessageBroadcaster from '../../helpers/useMessageBroadcaster';
import ListItemLink from '../ListItemLink/ListItemLink';
import { theme } from '../../theme/theme';
import { useLazyMerchantUsersControllerRetrieveUserAuthenticatorsSelfManageUrlQuery } from '../../../../services';
import AuthUtils from '../../auth/auth-utils';

interface GreetingComponentProps {
}

const GreetingComponent: FC<GreetingComponentProps> = () => {
    const user = useAppSelector(selectAuthenticatedUser);
    const isEnrolledInAuthenticatorApp = useAppSelector(selectIsEnrolledInAuthenticatorApp);
    const hasEnrolledAuthenticators = useAppSelector(selectHasEnrolledAuthenticators);
    const merchantId = useAppSelector(selectCurrentMerchantId);
    const isBodd = useAppSelector(selectIsBoddUser);
    const isBoddImpersonating = !!useAppSelector(selectIsBoddUserImpersonatingMerchant);
    const isAuthLoading = useAppSelector(selectIsAuthLoading);
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));
    const { broadcast } = useMessageBroadcaster();
    const isBoddUser = isBodd || isBoddImpersonating;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { ae: useAuthsignal } = (window as any).boddSettings;
    const [manageMfa] = useLazyMerchantUsersControllerRetrieveUserAuthenticatorsSelfManageUrlQuery();

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        broadcast({ type: MessageType.Logout }, true);
    };

    const handleMfa = async () => {
        const uid = user?.userId ?? '';
        const url = await manageMfa({
            userId: uid,
            merchantId: merchantId ?? -1,
            redirectUrl: window.location.href,
        }).unwrap();
        AuthUtils.launchAuthsignal(url);
    };

    const getShortProfileAlias = () => {
        if (typeof user?.email === 'string') {
            if (user?.email?.length! > 10) {
                return user?.email?.substring(0, 9).concat('..');
            }
            return user?.email;
        }
        return 'profile';
    };

    if (isAuthLoading) {
        return <div data-testid="GreetingComponent"><LinearProgress /></div>;
    }
    if (user) {
        return (
            <div data-testid="GreetingComponent">
                {isMobileLayout ? (
                        <List>
                            <Typography
                                sx={{ fontSize: 16, fontWeight: 600, ml: 3, mt: 3, mb: 1, mr: 1, textOverflow: 'ellipsis' }}
                                noWrap
                            >
                                {user?.email}
                            </Typography>
                            <RegionSelectorMenuItem onClose={() => handleMenuClose()} />
                            <ImpersonateMerchantMenuItem
                                merchantId={merchantId}
                                isBoddUser={isBoddUser}
                                closeMenu={handleMenuClose}
                            />
                            <ListItemLink icon={<LogoutIcon />} text="Logout" onClick={() => handleLogout()} />
                        </List>
                    )
                    : (
                        <>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    p: 0,
                                    borderRadius: 0,
                                    width: '100%',
                                }}
                                color="inherit"
                                variant="text"
                                onClick={handleMenuClick}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 82,
                                }}
                                >
                                    <Box><UserProfileIcon fontSize="24px" /></Box>
                                    <Typography component="span" sx={{ fontSize: '12px', lineHeight: 1, mb: 0 }}>
                                        {getShortProfileAlias()}
                                    </Typography>
                                    <ArrowDropDownIcon />
                                </Box>
                            </Button>
                            <Menu
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem disabled>{user?.email}</MenuItem>
                                <RegionSelectorMenuItem onClose={() => handleMenuClose()} />
                                <ImpersonateMerchantMenuItem
                                    merchantId={merchantId}
                                    isBoddUser={isBoddUser}
                                    closeMenu={() => handleMenuClose()}
                                />
                                {useAuthsignal && hasEnrolledAuthenticators && (
                                    <MenuItem
                                        onClick={() => handleMfa()}
                                    >
                                        {isEnrolledInAuthenticatorApp ? 'Manage MFA' : 'Set up MFA'}
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() => handleLogout()}
                                >
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )}
            </div>
        );
    }
    return (
        <Box
            sx={{ marginLeft: 'auto', marginRight: 'auto' }}
            data-testid="GreetingComponent"
        >
            <div>
                <LoginButton />
            </div>
        </Box>
    );
};
export default GreetingComponent;
